import { GameContainer, ModalContainer } from "./containers";
import Game from "./components/Game";

export default function App() {
  return (
    <GameContainer>
      <ModalContainer>
        <Game />
      </ModalContainer>
    </GameContainer>
  );
}
