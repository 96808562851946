import React from "react";
import "./Section.css";

interface SectionProps extends React.PropsWithChildren {
  className?: string;
}

export default function Section({ className, children }: SectionProps) {
  return (
    <section className={`Element--Section ${className}`}>{children}</section>
  );
}
