import { Button } from "../elements";
import Type from "../enums/Type";
import TypeColor from "../constants/TypeColor";
import TypeIcon from "./TypeIcon";
import "./TypePicker.css";

interface TypePickerProps {
  value: Type;
  onChange: (newType: Type) => void;
}

export default function TypePicker({ value, onChange }: TypePickerProps) {
  return (
    <section className="TypePicker">
      {Object.values(Type).map((type) => {
        const isSelected = value === type;
        return (
          <div key={type} className="Item">
            <Button
              variant="custom"
              onClick={() => {
                onChange(type);
              }}
            >
              <div
                className="Option"
                data-selected={isSelected}
                style={
                  isSelected
                    ? {
                        borderColor: TypeColor[type],
                      }
                    : undefined
                }
              >
                <TypeIcon type={type} />
              </div>
            </Button>
          </div>
        );
      })}
    </section>
  );
}
