import React from "react";
import GameState from "../interfaces/GameState";
import SpotName from "../enums/SpotName";
import Type from "../enums/Type";
import initialState from "../game/initialState";

interface GameContext extends GameState {
  handlePlaceEmptyCard: (spotName: SpotName) => void;
  handleRemoveCard: (spotName: SpotName) => void;
  handleAddDamageCounter: (spotName: SpotName, amount: number) => void;
  handleRemoveDamageCounter: (spotName: SpotName, amount: number) => void;
  handlePoisonedChange: (spotName: SpotName, poisoned: boolean) => void;
  handleBurnedChange: (spotName: SpotName, burned: boolean) => void;
  handleSetHP: (spotName: SpotName, hp?: number) => void;
  handleSwitch: (
    spotName: SpotName,
    newSpotName: SpotName,
    clearSpecialConditions?: boolean
  ) => void;
  handleTypeChange: (spotName: SpotName, type: Type) => void;
  handleResetGame: () => void;
}

export default React.createContext<GameContext>({
  ...initialState,
  handlePlaceEmptyCard: () => {},
  handleRemoveCard: () => {},
  handleAddDamageCounter: () => {},
  handleRemoveDamageCounter: () => {},
  handlePoisonedChange: () => {},
  handleBurnedChange: () => {},
  handleSetHP: () => {},
  handleSwitch: () => {},
  handleTypeChange: () => {},
  handleResetGame: () => {},
});
