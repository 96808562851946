import GameState from "../interfaces/GameState";

const initialState: GameState = {
  spots: {
    activeSpot: undefined,
    bench1: undefined,
    bench2: undefined,
    bench3: undefined,
    bench4: undefined,
    bench5: undefined,
  },
} as const;

export default initialState;
