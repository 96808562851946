import React from "react";
import "./Logo.css";

export default function Logo() {
  const [showsDisclaimer, setShowsDisclaimer] = React.useState(false);
  const timerRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setShowsDisclaimer(false);
    }, 3000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [showsDisclaimer]);

  return (
    <header className="Logo">
      <h1 onClick={() => setShowsDisclaimer((prev) => !prev)}>
        <strong className="Name">DamageCounter</strong>
        <span className="Domain">PRO</span>
      </h1>
      {showsDisclaimer && (
        <p>This site tracks nothing and does not use cookies.</p>
      )}
    </header>
  );
}
