enum GameAction {
  PLACE_EMPTY_CARD = "PLACE_EMPTY_CARD",
  REMOVE_CARD = "REMOVE_CARD",
  ADD_DAMAGE_COUNTER = "ADD_DAMAGE_COUNTER",
  REMOVE_DAMAGE_COUNTER = "REMOVE_DAMAGE_COUNTER",
  POISONED_CHANGE = "POISONED_CHANGE",
  BURNED_CHANGE = "BURNED_CHANGE",
  SET_HP = "SET_HP",
  SWITCH = "SWITCH",
  TYPE_CHANGE = "TYPE_CHANGE",
  RESET_GAME = "RESET_GAME",
}

export default GameAction;
