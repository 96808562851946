import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type RemoveDamageCounterPayload = {
  spotName: SpotName;
  amount: number;
};

function isRemoveDamageCounter(
  actionType: GameAction,
  payload: unknown
): payload is RemoveDamageCounterPayload {
  return actionType === GameAction.REMOVE_DAMAGE_COUNTER;
}

export default function handleRemoveDamageCounter(
  state: GameState,
  action: ReducerAction
) {
  if (isRemoveDamageCounter(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (!spot) {
      console.error("Spot is undefined");
      return state;
    }

    return {
      ...state,
      spots: {
        ...state.spots,
        [spotName]: {
          ...spot,
          damageCounters: Math.min(
            spot.hp || Infinity,
            Math.max(0, spot.damageCounters - action.payload.amount)
          ),
        },
      },
    };
  }

  return state;
}
