import Type from "../enums/Type";
import TypeColor from "../constants/TypeColor";
import COLORLESSTypeIconSource from "../images/type-COLORLESS.png";
import GRASSTypeIconSource from "../images/type-GRASS.png";
import FIRETypeIconSource from "../images/type-FIRE.png";
import WATERTypeIconSource from "../images/type-WATER.png";
import LIGHTNINGTypeIconSource from "../images/type-LIGHTNING.png";
import FIGHTINGTypeIconSource from "../images/type-FIGHTING.png";
import PSYCHICTypeIconSource from "../images/type-PSYCHIC.png";
import DARKNESSTypeIconSource from "../images/type-DARKNESS.png";
import METALTypeIconSource from "../images/type-METAL.png";
import DRAGONTypeIconSource from "../images/type-DRAGON.png";
import FAIRYTypeIconSource from "../images/type-FAIRY.png";
import "./TypeIcon.css";

const ICON_SOURCES = {
  [Type.COLORLESS]: COLORLESSTypeIconSource,
  [Type.GRASS]: GRASSTypeIconSource,
  [Type.FIRE]: FIRETypeIconSource,
  [Type.WATER]: WATERTypeIconSource,
  [Type.LIGHTNING]: LIGHTNINGTypeIconSource,
  [Type.FIGHTING]: FIGHTINGTypeIconSource,
  [Type.PSYCHIC]: PSYCHICTypeIconSource,
  [Type.DARKNESS]: DARKNESSTypeIconSource,
  [Type.METAL]: METALTypeIconSource,
  [Type.DRAGON]: DRAGONTypeIconSource,
  [Type.FAIRY]: FAIRYTypeIconSource,
} as const;

interface TypeIconProps {
  size?: number;
  type: Type;
  onClick?: () => void;
}

export default function TypeIcon({ size = 24, type, onClick }: TypeIconProps) {
  return (
    <div
      className="TypeIcon"
      style={{ width: size, height: size }}
      onClick={onClick}
    >
      <div />
      <img
        style={{
          backgroundColor: TypeColor[type],
        }}
        alt={type}
        src={ICON_SOURCES[type]}
      />
    </div>
  );
}
