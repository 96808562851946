import GameAction from "../enums/GameAction";
import GameState from "../interfaces/GameState";

import handlePlaceEmptyCard from "./actions/handlePlaceEmptyCard";
import handleRemoveCard from "./actions/handleRemoveCard";
import handleAddDamageCounter from "./actions/handleAddDamageCounter";
import handleRemoveDamageCounter from "./actions/handleRemoveDamageCounter";
import handlePoisonedChange from "./actions/handlePoisonedChange";
import handleBurnedChange from "./actions/handleBurnedChange";
import handleSetHP from "./actions/handleSetHP";
import handleSwitch from "./actions/handleSwitch";
import handleTypeChange from "./actions/handleTypeChange";
import handleResetGame from "./actions/handleResetGame";

interface Action {
  type: GameAction;
  payload: unknown;
}

export default function reducer(state: GameState, action: Action) {
  if (action.type === GameAction.PLACE_EMPTY_CARD) {
    return handlePlaceEmptyCard(state, action);
  }

  if (action.type === GameAction.REMOVE_CARD) {
    return handleRemoveCard(state, action);
  }

  if (action.type === GameAction.ADD_DAMAGE_COUNTER) {
    return handleAddDamageCounter(state, action);
  }

  if (action.type === GameAction.REMOVE_DAMAGE_COUNTER) {
    return handleRemoveDamageCounter(state, action);
  }

  if (action.type === GameAction.POISONED_CHANGE) {
    return handlePoisonedChange(state, action);
  }

  if (action.type === GameAction.BURNED_CHANGE) {
    return handleBurnedChange(state, action);
  }

  if (action.type === GameAction.SET_HP) {
    return handleSetHP(state, action);
  }

  if (action.type === GameAction.SWITCH) {
    return handleSwitch(state, action);
  }

  if (action.type === GameAction.TYPE_CHANGE) {
    return handleTypeChange(state, action);
  }

  if (action.type === GameAction.RESET_GAME) {
    return handleResetGame(state, action);
  }

  return state;
}
