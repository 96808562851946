export default function getDamageCriticalityThreshold(
  damageCounters: number,
  remainingHP: number,
  hp: number
) {
  if (hp === 30) {
    if (damageCounters >= 2) return "critical";
    if (damageCounters >= 1) return "danger";
    return "normal";
  }

  if (hp === 20) {
    if (damageCounters >= 1) return "critical";
    return "normal";
  }

  const percentage = remainingHP / hp;

  if (percentage > 0.5) return "normal";
  if (percentage > 0.25) return "danger";
  return "critical";
}
