import React from "react";
import { Button } from "../elements";
import "./HPPicker.css";

interface HPPickerProps {
  value?: number;
  onChange: (newValue?: number) => void;
  disableStepButtons?: boolean;
}

// const LOW_HP_PRESETS = [10, 20, 30, 40] as const;

const NORMAL_HP_PRESETS = [
  40, 50, 60, 70, 80, 90, 100, 110, 120,
  // 130, 140, 150, 160, 170, 180, 190,
  150, 200, 250,
] as const;

// const PRESETS_200 = [
//   200, 210, 220, 230, 240,
//   // 250, 260, 270, 280, 290
// ] as const;

// const PRESETS_300 = [300, 310, 320, 340, 350];

export default function HPPicker({
  value,
  onChange,
  disableStepButtons,
}: HPPickerProps) {
  const [tempHPInputValue, setTempHPInputValue] = React.useState<
    number | undefined
  >(value);
  React.useEffect(() => {
    setTempHPInputValue(value);
  }, [value]);
  return (
    <div className="HPPicker">
      <section className="HPPresetPicker">
        {/* <div className="Presets Low">
          {LOW_HP_PRESETS.map((hpPreset) => {
            return (
              <Button
                key={hpPreset}
                size="small"
                variant="gray"
                onClick={() => {
                  onChange(hpPreset);
                }}
              >
                <div className="Value">{hpPreset}</div>
              </Button>
            );
          })}
        </div> */}
        <div className="Presets Normal">
          {NORMAL_HP_PRESETS.map((hpPreset) => {
            return (
              <Button
                key={hpPreset}
                size="small"
                variant="gray"
                onClick={() => {
                  onChange(hpPreset);
                }}
              >
                <div className="Value">{hpPreset}</div>
              </Button>
            );
          })}
        </div>
        {/* <div className="Presets High">
          {PRESETS_200.map((hpPreset) => {
            return (
              <Button
                key={hpPreset}
                size="small"
                variant="gray"
                onClick={() => {
                  onChange(hpPreset);
                }}
              >
                <div className="Value">{hpPreset}</div>
              </Button>
            );
          })}
        </div> */}

        {/* <div className="Presets High">
          {PRESETS_300.map((hpPreset) => {
            return (
              <Button
                key={hpPreset}
                size="small"
                variant="gray"
                onClick={() => {
                  onChange(hpPreset);
                }}
              >
                <div className="Value">{hpPreset}</div>
              </Button>
            );
          })}
        </div> */}

        <section className="HPInput">
          {!disableStepButtons ? (
            <Button
              disabled={!value}
              onClick={() => {
                onChange((value || 10) - 10);
              }}
            >
              &minus;
            </Button>
          ) : (
            <div />
          )}
          <div className="Form">
            HP
            <input
              placeholder="0"
              type="text"
              inputMode="numeric"
              min={0}
              value={tempHPInputValue || ""}
              step={10}
              onBlur={() => {
                if (tempHPInputValue !== value) {
                  onChange(tempHPInputValue);
                }
              }}
              onChange={(event) => {
                const value = Number(event.target.value) || undefined;
                setTempHPInputValue(value);
              }}
            />
          </div>
          {!disableStepButtons ? (
            <Button
              onClick={() => {
                onChange((value || 0) + 10);
              }}
            >
              +
            </Button>
          ) : (
            <div />
          )}
        </section>
      </section>
    </div>
  );
}
