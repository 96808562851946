import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type AddDamageCounterPayload = {
  spotName: SpotName;
  amount: number;
};

function isAddDamageCounter(
  actionType: GameAction,
  payload: unknown
): payload is AddDamageCounterPayload {
  return actionType === GameAction.ADD_DAMAGE_COUNTER;
}

export default function handleAddDamageCounter(
  state: GameState,
  action: ReducerAction
) {
  if (isAddDamageCounter(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (!spot) {
      console.error("Spot is undefined");
      return state;
    }

    const maxDamageCounters =
      typeof spot.hp === "number" ? spot.hp / 10 : Infinity;

    return {
      ...state,
      spots: {
        ...state.spots,
        [spotName]: {
          ...spot,
          damageCounters: Math.min(
            maxDamageCounters,
            spot.damageCounters + action.payload.amount
          ),
        },
      },
    };
  }

  return state;
}
