import React from "react";
import { Button, Section } from "../elements";
import { useGame } from "../hooks";
import SpotName from "../enums/SpotName";
import TypeColor from "../constants/TypeColor";
import TypeBkgImgSrc from "../constants/TypeBkgImgSrc";
import TypeTextColor from "../constants/TypeTextColor";
import TypePicker from "./TypePicker";
import HPPicker from "./HPPicker";
import "./CardCreator.css";

interface CardCreatorProps {
  editingSpot?: SpotName;
  onClose: () => void;
}

export default function CardCreator({
  editingSpot,
  onClose,
}: CardCreatorProps) {
  const [stepsCompleted, setStepsCompleted] = React.useState({
    hp: false,
    type: false,
  });

  const game = useGame();

  React.useEffect(() => {
    if (stepsCompleted.hp && stepsCompleted.type) {
      setStepsCompleted({ hp: false, type: false });
      onClose();
    }
  }, [stepsCompleted, onClose]);

  if (!editingSpot) {
    return <aside className="CardCreator" data-open="false"></aside>;
  }

  const pokemon = game.spots[editingSpot];

  if (!pokemon) {
    return <aside className="CardCreator" data-open="false"></aside>;
  }

  return (
    <aside className="CardCreator" data-open="true">
      <div
        className="Content"
        style={{
          backgroundColor: TypeColor[pokemon.type],
          backgroundImage: `url(${TypeBkgImgSrc[pokemon.type]})`,
          color: TypeTextColor[pokemon.type],
        }}
      >
        <Section>
          <HPPicker
            value={pokemon.hp}
            onChange={(value) => {
              game.handleSetHP(editingSpot, value);
              setStepsCompleted((prev) => {
                return {
                  ...prev,
                  hp: true,
                };
              });
            }}
          />
        </Section>

        <Section>
          <TypePicker
            value={pokemon.type}
            onChange={(newType) => {
              setStepsCompleted((prev) => {
                return {
                  ...prev,
                  type: true,
                };
              });
              game.handleTypeChange(editingSpot, newType);
            }}
          />
        </Section>

        <footer className="Buttons">
          <Button
            size="large"
            onClick={() => {
              onClose();
              game.handleRemoveCard(editingSpot);
            }}
          >
            Cancel
          </Button>

          <Button size="large" onClick={onClose}>
            Place Card
          </Button>
        </footer>
      </div>
    </aside>
  );
}
