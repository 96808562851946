import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type SetHPPayload = {
  spotName: SpotName;
  hp?: number;
};

function isSetHP(
  actionType: GameAction,
  payload: unknown
): payload is SetHPPayload {
  return actionType === GameAction.SET_HP;
}

export default function handleSetHP(state: GameState, action: ReducerAction) {
  if (isSetHP(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (!spot) {
      console.error(GameAction.SET_HP, "Spot is undefined");
      return state;
    }

    const damageCounters =
      action.payload.hp && action.payload.hp / 10 < spot.damageCounters
        ? Math.floor(action.payload.hp / 10)
        : spot.damageCounters;

    return {
      ...state,
      spots: {
        ...state.spots,
        [spotName]: {
          ...spot,
          hp: action.payload.hp,
          damageCounters,
        },
      },
    };
  }

  return state;
}
