import React from "react";
import { createPortal } from "react-dom";
import { useModals } from "../hooks";
import "./Modal.css";

const MODAL_ROOT = document.getElementById("modal-root");

interface ModalProps extends React.PropsWithChildren {
  isOpen: boolean;
  onOpenEnd?: () => void;
  onCloseEnd?: () => void;
  variant?: "normal" | "custom";
  style?: React.StyleHTMLAttributes<HTMLDivElement>;
}

enum RenderState {
  CLOSED = "CLOSED",
  OPENING = "OPENING",
  VISIBLE = "VISIBLE",
  CLOSING = "CLOSING",
}

export default function Modal({
  isOpen,
  onOpenEnd,
  onCloseEnd,
  style,
  variant = "normal",
  children,
}: ModalProps) {
  const id = React.useId();
  const hasRendered = React.useRef(false);
  const { openModalIds, openModal, closeModal } = useModals();

  const zIndex = openModalIds.indexOf(id);
  const isTopMostModal = openModalIds.length - 1 === zIndex;

  const [renderState, setRenderState] = React.useState<RenderState>(
    RenderState.CLOSED
  );

  const handleOpenStart = React.useCallback(() => {
    setRenderState((prev) => {
      if (prev !== RenderState.VISIBLE) {
        return RenderState.OPENING;
      }
      return prev;
    });
    openModal(id);
  }, [openModal, id]);

  const handleOpenEnd = React.useCallback(() => {
    if (typeof onOpenEnd === "function") {
      onOpenEnd();
    }
    setRenderState(RenderState.VISIBLE);
  }, [onOpenEnd]);

  const handleCloseStart = React.useCallback(() => {
    setRenderState((prev) => {
      if (prev !== RenderState.CLOSED) {
        closeModal(id);
        return RenderState.CLOSING;
      }
      return prev;
    });
  }, [closeModal, id]);

  const handleCloseEnd = React.useCallback(() => {
    if (typeof onCloseEnd === "function") {
      onCloseEnd();
    }
    setRenderState(RenderState.CLOSED);
  }, [onCloseEnd]);

  React.useEffect(() => {
    if (hasRendered.current) {
      if (isOpen) {
        handleOpenStart();
      } else {
        handleCloseStart();
      }
    } else {
      hasRendered.current = true;
    }
  }, [isOpen, handleOpenStart, handleCloseStart]);

  return createPortal(
    <div
      onTransitionEnd={isOpen ? handleOpenEnd : handleCloseEnd}
      className="Element--Modal"
      data-render-state={renderState}
      data-regressed={!isTopMostModal}
      style={{
        ...style,
        zIndex,
      }}
      data-variant={variant}
    >
      <div className="Backdrop" />
      <div className="Content">{children}</div>
    </div>,
    MODAL_ROOT || document.body
  );
}
