import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type PoisonedChangePayload = {
  spotName: SpotName;
  poisoned: boolean;
};

function isPoisonedChange(
  actionType: GameAction,
  payload: unknown
): payload is PoisonedChangePayload {
  return actionType === GameAction.POISONED_CHANGE;
}

export default function handlePoisonedChange(
  state: GameState,
  action: ReducerAction
) {
  if (isPoisonedChange(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (!spot) {
      console.error(GameAction.POISONED_CHANGE, "Spot is undefined");
      return state;
    }

    return {
      ...state,
      spots: {
        ...state.spots,
        [spotName]: {
          ...spot,
          poisoned: action.payload.poisoned,
        },
      },
    };
  }

  return state;
}
