import { getDamageCounterThreshold } from "../helpers";
import "./DamageIndicator.css";

interface DamageIndicatorProps {
  damageCounters: number;
  variant?: "damage" | "heal";
}

export default function DamageIndicator({
  damageCounters,
  variant = "damage",
}: DamageIndicatorProps) {
  return (
    <div
      className="DamageIndicator"
      data-variant={variant}
      data-threshold={getDamageCounterThreshold(damageCounters)}
    >
      {damageCounters * 10}
    </div>
  );
}
