import { Button } from "../elements";
import "./DamageCounterControl.css";

interface DamageCounterControlProps {
  onRemove: () => void;
  onAdd: () => void;
  damageCounters: number;
  textColor?: "black" | "white";
}

function getCounterDistribution(damageCounters: number) {
  const hundreds = Math.floor((damageCounters - (damageCounters % 10)) / 10);
  let remaining = damageCounters - hundreds * 10;

  const fifties = Math.floor((remaining - (remaining % 5)) / 5);

  remaining = remaining - fifties * 5;

  return {
    hundreds,
    fifties: fifties,
    tens: remaining,
  };
}

export default function DamageCounterControl({
  damageCounters,
  onRemove,
  onAdd,
  textColor = "black",
}: DamageCounterControlProps) {
  const damageCounterDistribution = getCounterDistribution(damageCounters);

  return (
    <div className="DamageCounterControl" style={{ color: textColor }}>
      <Button onClick={onRemove}>&minus;</Button>
      <div className="Count">
        <div>
          {damageCounters} counter{damageCounters === 1 ? "" : "s"}
        </div>
        <div className="Counters">
          {Array.from({ length: damageCounterDistribution.hundreds })
            .fill(undefined)
            .map((_, index) => {
              return (
                <div key={index} className="Counter" data-value="100">
                  <div>100</div>
                </div>
              );
            })}
          {Array.from({ length: damageCounterDistribution.fifties })
            .fill(undefined)
            .map((_, index) => {
              return (
                <div key={index} className="Counter" data-value="50">
                  <div>50</div>
                </div>
              );
            })}
          {Array.from({ length: damageCounterDistribution.tens })
            .fill(undefined)
            .map((_, index) => {
              return (
                <div key={index} className="Counter" data-value="10">
                  <div>10</div>
                </div>
              );
            })}
        </div>
      </div>
      <Button onClick={onAdd}>+</Button>
    </div>
  );
}
