import React from "react";
import { ModalContext } from "../contexts";

interface ModalContainerProps extends React.PropsWithChildren {}

export default function ModalContainer({ children }: ModalContainerProps) {
  const [openModalIds, setOpenModalIds] = React.useState<string[]>([]);

  const openModal = React.useCallback((idToAdd: string) => {
    setOpenModalIds((prev) => {
      return [...prev, idToAdd];
    });
  }, []);

  const closeModal = React.useCallback((idToRemove: string) => {
    setOpenModalIds((prev) => {
      return prev.filter((id) => id !== idToRemove);
    });
  }, []);

  const hasOpenModals = !!openModalIds.length;

  return (
    <ModalContext.Provider
      value={{ openModalIds, hasOpenModals, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
}
