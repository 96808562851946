import Type from "../enums/Type";
import COLORLESSSrc from "../images/type-bkg-COLORLESS.jpg";
import GRASSSrc from "../images/type-bkg-GRASS.jpg";
import FIRESrc from "../images/type-bkg-FIRE.jpg";
import WATERSrc from "../images/type-bkg-WATER.jpg";
import LIGHTNINGSrc from "../images/type-bkg-LIGHTNING.jpg";
import FIGHTINGSrc from "../images/type-bkg-FIGHTING.jpg";
import PSYCHICSrc from "../images/type-bkg-PSYCHIC.jpg";
import DARKNESSSrc from "../images/type-bkg-DARKNESS.jpg";
import METALSrc from "../images/type-bkg-METAL.jpg";
import DRAGONSrc from "../images/type-bkg-DRAGON.jpg";
import FAIRYSrc from "../images/type-bkg-FAIRY.jpg";

const TypeBkgImgSrc = {
  [Type.COLORLESS]: COLORLESSSrc,
  [Type.GRASS]: GRASSSrc,
  [Type.FIRE]: FIRESrc,
  [Type.WATER]: WATERSrc,
  [Type.LIGHTNING]: LIGHTNINGSrc,
  [Type.FIGHTING]: FIGHTINGSrc,
  [Type.PSYCHIC]: PSYCHICSrc,
  [Type.DARKNESS]: DARKNESSSrc,
  [Type.METAL]: METALSrc,
  [Type.DRAGON]: DRAGONSrc,
  [Type.FAIRY]: FAIRYSrc,
} as const;

export default TypeBkgImgSrc;
