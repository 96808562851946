import { Button } from "../elements";
import { useGame } from "../hooks";

export default function Settings() {
  const game = useGame();

  return (
    <Button
      size="small"
      variant="gray"
      onClick={() => {
        if (window.confirm("Are you sure?")) {
          game.handleResetGame();
        }
      }}
    >
      Reset
    </Button>
  );
}
