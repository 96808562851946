import Type from "../enums/Type";

const TypeColor = {
  [Type.COLORLESS]: "#D2D0CF",
  [Type.GRASS]: "#19A648",
  [Type.FIRE]: "#D8223B",
  [Type.WATER]: "#05A8D9",
  [Type.LIGHTNING]: "#FCD021",
  [Type.FIGHTING]: "#B16232",
  [Type.PSYCHIC]: "#957DAB",
  [Type.DARKNESS]: "#2E7077",
  [Type.METAL]: "#9B9E8C",
  [Type.DRAGON]: "#948F31",
  [Type.FAIRY]: "#D6457E",
} as const;

export default TypeColor;
