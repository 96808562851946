import React from "react";
import CoinHeadsSource from "../images/coin-heads.png";
import CoinTailsSource from "../images/coin-tails.png";
import "./Coin.css";

enum Face {
  HEADS = "HEADS",
  TAILS = "TAILS",
}

export default function Coin() {
  const pressTimestampRef = React.useRef(0);
  const [values, setValues] = React.useState({
    face: Face.HEADS,
    deg: 0,
    duration: 1000,
  });

  const recordPressTime = React.useCallback(() => {
    pressTimestampRef.current = Date.now();
  }, []);

  const flipCoin = React.useCallback(() => {
    const newDuration = Math.max(
      1500,
      Math.min(5000, Date.now() - pressTimestampRef.current)
    );

    const newFace = Math.floor(Math.random() * 2) ? Face.HEADS : Face.TAILS;

    const timesToFlip = Math.round(newDuration / 175);

    const direction = newFace === Face.HEADS ? -1 : 1;

    setValues((prev) => {
      let newDeg = prev.deg + timesToFlip * 180 * direction;

      if (newFace === Face.HEADS && newDeg % 360 !== 0) {
        newDeg = newDeg - 180;
      }
      if (newFace === Face.TAILS && newDeg % 360 === 0) {
        newDeg = newDeg + 180;
      }

      return {
        deg: newDeg,
        duration: newDuration,
        face: newFace,
      };
    });
  }, []);

  return (
    <>
      <div
        className="Coin"
        onMouseDown={"ontouchstart" in window ? undefined : recordPressTime}
        onTouchStart={"ontouchstart" in window ? recordPressTime : undefined}
        onMouseUp={"ontouchstart" in window ? undefined : flipCoin}
        onTouchEnd={"ontouchstart" in window ? flipCoin : undefined}
      >
        <div>
          <div
            className="CoinFaces"
            style={{
              transform: `rotateY(${values.deg}deg)`,
              transitionDuration: `${values.duration}ms`,
            }}
            data-face={values.face}
          >
            <div className="Heads" data-face={Face.HEADS}>
              <img alt="Heads" src={CoinHeadsSource} />
            </div>
            <div className="Tails" data-face={Face.TAILS}>
              <img alt="Tails" src={CoinTailsSource} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
