import React from "react";
import { createPortal } from "react-dom";
import "./Popup.css";

const MODAL_ROOT = document.getElementById("modal-root");

interface PopupProps extends React.PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}

export default function Popup({ isOpen, onClose, children }: PopupProps) {
  return createPortal(
    <div className="Element--Popup" data-open={isOpen}>
      <div className="Backdrop" onClick={onClose} />
      <div className="Content">{children}</div>
    </div>,
    MODAL_ROOT || document.body
  );
}
