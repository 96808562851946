import React from "react";
import { Button, Section, Modal } from "../elements";
import { useGame } from "../hooks";
import SpotName from "../enums/SpotName";
import TypeColor from "../constants/TypeColor";
import TypeBkgImgSrc from "../constants/TypeBkgImgSrc";
import TypeTextColor from "../constants/TypeTextColor";
import SpecialConditionPoisonedSource from "../images/special-condition-poisoned.png";
import SpecialConditionBurnedSource from "../images/special-condition-burned.png";
import SpotNameLocales from "../locales/SpotNameLocales";
import TypePicker from "./TypePicker";
import HPPicker from "./HPPicker";
import DamageCounterControl from "./DamageCounterControl";
import "./CardEditor.css";

interface CardEditorProps {
  editingSpot?: SpotName;
  onClose: () => void;
}

export default function CardEditor({ editingSpot, onClose }: CardEditorProps) {
  const game = useGame();
  const [hpEditorModalIsOpen, setHPEditorModalIsOpen] = React.useState(false);

  if (!editingSpot) {
    return <aside className="CardEditor" data-open="false"></aside>;
  }

  const pokemon = game.spots[editingSpot];

  if (!pokemon) {
    return <aside className="CardEditor" data-open="false"></aside>;
  }

  const remainingHP = (pokemon.hp || 0) - pokemon.damageCounters * 10;

  const textColor = TypeTextColor[pokemon.type];

  return (
    <aside className="CardEditor" data-open="true">
      <div
        className="Content"
        style={{
          backgroundColor: TypeColor[pokemon.type],
          backgroundImage: `url(${TypeBkgImgSrc[pokemon.type]})`,
          color: textColor,
        }}
      >
        <Section className="HP">
          <Button
            disabled={!pokemon.hp}
            onClick={() => {
              game.handleSetHP(editingSpot, (pokemon.hp || 10) - 10);
            }}
          >
            &minus;
          </Button>
          <Button onClick={() => setHPEditorModalIsOpen(true)}>
            <div className="Display">
              <small>HP</small>{" "}
              <span className="Value">
                {pokemon.hp ? `${remainingHP}/${pokemon.hp}` : "Not Set"}
              </span>
            </div>
          </Button>
          <Button
            disabled={!pokemon.hp}
            onClick={() => {
              game.handleSetHP(editingSpot, (pokemon.hp || 0) + 10);
            }}
          >
            +
          </Button>
        </Section>

        <Modal isOpen={hpEditorModalIsOpen}>
          <section>
            <HPPicker
              value={pokemon.hp}
              onChange={(value) => {
                setHPEditorModalIsOpen(false);
                game.handleSetHP(editingSpot, value);
              }}
              disableStepButtons
            />

            <footer style={{ marginTop: "1em" }}>
              <Button
                size="large"
                onClick={() => setHPEditorModalIsOpen(false)}
              >
                Cancel
              </Button>
            </footer>
          </section>
        </Modal>

        <Section className="DamageCounters">
          <DamageCounterControl
            damageCounters={pokemon.damageCounters}
            onRemove={() => game.handleRemoveDamageCounter(editingSpot, 1)}
            onAdd={() => game.handleAddDamageCounter(editingSpot, 1)}
            textColor={textColor}
          />
        </Section>

        <Section>
          <TypePicker
            value={pokemon.type}
            onChange={(newType) => {
              game.handleTypeChange(editingSpot, newType);
            }}
          />
        </Section>

        <Section className="SpecialConditions">
          <Button
            size="small"
            onClick={() => {
              game.handlePoisonedChange(editingSpot, !pokemon.poisoned);
            }}
          >
            <div
              className="SpecialConditionToggle Poisoned"
              data-selected={pokemon.poisoned}
            >
              <img alt="Poisoned" src={SpecialConditionPoisonedSource} />
              Poisoned
            </div>
          </Button>
          <Button
            size="small"
            onClick={() => {
              game.handleBurnedChange(editingSpot, !pokemon.burned);
            }}
          >
            <div
              className="SpecialConditionToggle Burned"
              data-selected={pokemon.burned}
            >
              <img alt="Burned" src={SpecialConditionBurnedSource} />
              Burned
            </div>
          </Button>
        </Section>

        <Section className="Move">
          {Object.values(SpotName).map((newSpotName) => {
            const isDisabled = editingSpot === newSpotName;
            return (
              <div key={newSpotName} style={{ gridArea: newSpotName }}>
                <Button
                  size="small"
                  disabled={isDisabled}
                  onClick={() => {
                    if (
                      newSpotName !== SpotName.ACTIVE_SPOT &&
                      (pokemon.burned || pokemon.poisoned)
                    ) {
                      if (
                        window.confirm(
                          "Your Pokémon has a special condition and you’re moving it to the bench. Clear the special conditions?"
                        )
                      ) {
                        game.handleSwitch(editingSpot, newSpotName, true);
                      } else {
                        game.handleSwitch(editingSpot, newSpotName);
                      }
                    } else {
                      game.handleSwitch(editingSpot, newSpotName);
                    }

                    onClose();
                  }}
                >
                  {SpotNameLocales[newSpotName]}
                </Button>
              </div>
            );
          })}
        </Section>

        <footer className="Buttons">
          <Button
            size="large"
            variant="delete"
            onClick={() => {
              if (window.confirm("Discard this card?")) {
                onClose();
                game.handleRemoveCard(editingSpot);
              }
            }}
          >
            Discard
          </Button>

          <Button size="large" onClick={onClose}>
            Done
          </Button>
        </footer>
      </div>
    </aside>
  );
}
