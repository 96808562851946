import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type SwitchPayload = {
  spotName: SpotName;
  newSpotName: SpotName;
  clearSpecialConditions?: boolean;
};

function isSwitch(
  actionType: GameAction,
  payload: unknown
): payload is SwitchPayload {
  return actionType === GameAction.SWITCH;
}

export default function handleSwitch(state: GameState, action: ReducerAction) {
  if (isSwitch(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const newSpotName = action.payload.newSpotName;

    const spot = state.spots[spotName];
    const newSpot = state.spots[newSpotName];

    if (!spot) {
      console.error(GameAction.SWITCH, "Spot is undefined");
      return state;
    }

    const newArrangement = {
      [spotName]: newSpot,
      [newSpotName]: action.payload.clearSpecialConditions
        ? { ...spot, burned: false, poisoned: false }
        : spot,
    };

    return {
      ...state,
      spots: {
        ...state.spots,
        ...newArrangement,
      },
    };
  }

  return state;
}
