import React from "react";
import { Button } from "../elements";
import { useGame } from "../hooks";
import SpotName from "../enums/SpotName";
import DamageIndicator from "./DamageIndicator";
import "./DoDamage.css";

interface DoDamageProps {
  spotName?: SpotName;
  variant: "damage" | "heal";
  onClose: () => void;
}

const PRESETS = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150, 160, 170, 180, 190,
] as const;

export default function DoDamage({
  spotName,
  variant = "damage",
  onClose,
}: DoDamageProps) {
  const game = useGame();
  const [amount, setAmount] = React.useState<number>(0);

  return (
    <aside className="DoDamage" data-open="true">
      <div className="Backdrop" onClick={onClose} />
      <section className="Content">
        <div className="PresetButtons">
          {PRESETS.map((preset) => {
            return (
              <Button
                key={preset}
                variant="custom"
                onClick={() => {
                  setAmount(preset);
                  onClose();
                  if (spotName) {
                    if (variant === "damage") {
                      game.handleAddDamageCounter(spotName, preset / 10);
                    } else {
                      game.handleRemoveDamageCounter(spotName, preset / 10);
                    }
                  }
                }}
              >
                <DamageIndicator
                  key={preset}
                  variant={variant}
                  damageCounters={preset / 10}
                />
              </Button>
            );
          })}
        </div>
        <div className="CustomDamage">
          <input
            type="number"
            inputMode="numeric"
            step={10}
            value={amount || ""}
            onChange={(event) => {
              const value = Number(event.target.value) || 0;
              setAmount(value);
            }}
            placeholder="0"
          />
          <Button
            className="Button"
            disabled={!amount}
            onClick={() => {
              onClose();
              if (spotName) {
                if (variant === "damage") {
                  game.handleAddDamageCounter(
                    spotName,
                    Math.floor(amount / 10)
                  );
                } else {
                  game.handleRemoveDamageCounter(
                    spotName,
                    Math.floor(amount / 10)
                  );
                }
              }
            }}
          >
            {variant === "damage" ? "Damage" : "Heal"}
          </Button>
        </div>
        <footer className="CancelButton">
          <Button size="large" onClick={onClose}>
            Cancel
          </Button>
        </footer>
      </section>
    </aside>
  );
}
