import React from "react";
import { useModals } from "../hooks";
import Logo from "./Logo";
import "./Field.css";

interface FieldProps {
  settingsSpot: React.ReactNode;
  activeSpot: React.ReactNode;
  coin: React.ReactNode;
  bench1: React.ReactNode;
  bench2: React.ReactNode;
  bench3: React.ReactNode;
  bench4: React.ReactNode;
  bench5: React.ReactNode;
}

export default function Field({
  settingsSpot,
  activeSpot,
  coin,
  bench1,
  bench2,
  bench3,
  bench4,
  bench5,
}: FieldProps) {
  const { hasOpenModals } = useModals();

  return (
    <main className="Field" data-modal-open={hasOpenModals}>
      <div className="LogoSpot">
        <Logo />
      </div>
      <div className="SettingsSpot">{settingsSpot}</div>
      <div className="ActiveSpot">{activeSpot}</div>
      <div className="CoinSpot">{coin}</div>
      <div className="Bench">
        {bench1}
        {bench2}
        {bench3}
        {bench4}
        {bench5}
      </div>
    </main>
  );
}
