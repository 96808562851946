import SpotName from "../enums/SpotName";

const SpotNameLocales = {
  [SpotName.ACTIVE_SPOT]: "Active Spot",
  [SpotName.BENCH_1]: "B1",
  [SpotName.BENCH_2]: "B2",
  [SpotName.BENCH_3]: "B3",
  [SpotName.BENCH_4]: "B4",
  [SpotName.BENCH_5]: "B5",
} as const;

export default SpotNameLocales;
