import { Button } from "../elements";
import SpotName from "../enums/SpotName";
import SpotNameLocales from "../locales/SpotNameLocales";
import "./EmptySpot.css";

interface EmptySpotProps {
  name: SpotName;
  onClick: () => void;
}

export default function EmptySpot({ name, onClick }: EmptySpotProps) {
  return (
    <Button variant="custom" onClick={onClick}>
      <div className="EmptySpot">
        <span>{SpotNameLocales[name]}</span>
      </div>
    </Button>
  );
}
