import GameAction from "../../enums/GameAction";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type ResetGamePayload = {
  initialState: GameState;
};

function isResetGame(
  actionType: GameAction,
  payload: unknown
): payload is ResetGamePayload {
  return actionType === GameAction.RESET_GAME;
}

export default function handleResetGame(
  state: GameState,
  action: ReducerAction
) {
  if (isResetGame(action.type, action.payload)) {
    return action.payload.initialState;
  }

  return state;
}
