import React from "react";
import { GameContext } from "../contexts";
import reducer from "../game/reducer";
import initialState from "../game/initialState";
import SpotName from "../enums/SpotName";
import GameAction from "../enums/GameAction";
import Type from "../enums/Type";

export default function GameContainer({ children }: React.PropsWithChildren) {
  const stateFromLocalStorage = JSON.parse(
    localStorage.getItem("gameState") || JSON.stringify(initialState)
  );

  const [state, dispatch] = React.useReducer(reducer, stateFromLocalStorage);

  React.useEffect(() => {
    localStorage.setItem("gameState", JSON.stringify(state));
  }, [state]);

  const handlePlaceEmptyCard = React.useCallback((spotName: SpotName) => {
    dispatch({
      type: GameAction.PLACE_EMPTY_CARD,
      payload: { spotName },
    });
  }, []);

  const handleRemoveCard = React.useCallback((spotName: SpotName) => {
    dispatch({
      type: GameAction.REMOVE_CARD,
      payload: { spotName },
    });
  }, []);

  const handleAddDamageCounter = React.useCallback(
    (spotName: SpotName, amount: number) => {
      dispatch({
        type: GameAction.ADD_DAMAGE_COUNTER,
        payload: { spotName, amount },
      });
    },
    []
  );

  const handleRemoveDamageCounter = React.useCallback(
    (spotName: SpotName, amount: number) => {
      dispatch({
        type: GameAction.REMOVE_DAMAGE_COUNTER,
        payload: { spotName, amount },
      });
    },
    []
  );

  const handlePoisonedChange = React.useCallback(
    (spotName: SpotName, poisoned: boolean) => {
      dispatch({
        type: GameAction.POISONED_CHANGE,
        payload: { spotName, poisoned },
      });
    },
    []
  );

  const handleBurnedChange = React.useCallback(
    (spotName: SpotName, burned: boolean) => {
      dispatch({
        type: GameAction.BURNED_CHANGE,
        payload: { spotName, burned },
      });
    },
    []
  );

  const handleSetHP = React.useCallback((spotName: SpotName, hp?: number) => {
    dispatch({
      type: GameAction.SET_HP,
      payload: { spotName, hp },
    });
  }, []);

  const handleSwitch = React.useCallback(
    (
      spotName: SpotName,
      newSpotName: SpotName,
      clearSpecialConditions?: boolean
    ) => {
      dispatch({
        type: GameAction.SWITCH,
        payload: { spotName, newSpotName, clearSpecialConditions },
      });
    },
    []
  );

  const handleTypeChange = React.useCallback(
    (spotName: SpotName, type: Type) => {
      dispatch({
        type: GameAction.TYPE_CHANGE,
        payload: { spotName, type },
      });
    },
    []
  );

  const handleResetGame = React.useCallback(() => {
    dispatch({ type: GameAction.RESET_GAME, payload: { initialState } });
  }, []);

  return (
    <GameContext.Provider
      value={{
        ...state,
        handlePlaceEmptyCard,
        handleRemoveCard,
        handleAddDamageCounter,
        handleRemoveDamageCounter,
        handlePoisonedChange,
        handleBurnedChange,
        handleSetHP,
        handleSwitch,
        handleTypeChange,
        handleResetGame,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
