import React from "react";
import { Modal, Popup } from "../elements";
import { useGame } from "../hooks";
import SpotName from "../enums/SpotName";
import Settings from "./Settings";
import Field from "./Field";
import Card from "./Card";
import Coin from "./Coin";
import CardCreator from "./CardCreator";
import CardEditor from "./CardEditor";
import DoDamage from "./DoDamage";

export default function App() {
  const game = useGame();
  const [creatorModalIsOpen, setCreatorModalIsOpen] = React.useState(false);
  const [editorModalIsOpen, setEditorModalIsOpen] = React.useState(false);
  const [editingSpot, setEditingSpot] = React.useState<SpotName | undefined>(
    undefined
  );

  const handleCreatorOpen = React.useCallback((spotName: SpotName) => {
    setEditingSpot(spotName);
    setCreatorModalIsOpen(true);
  }, []);

  const handleEditorOpen = React.useCallback(
    (spotName: SpotName) => {
      if (!game.spots.activeSpot) {
        game.handleSwitch(spotName, SpotName.ACTIVE_SPOT);
      } else {
        setEditingSpot(spotName);
        setEditorModalIsOpen(true);
      }
    },
    [game]
  );

  const [damageSpot, setDamageSpot] = React.useState<SpotName | undefined>(
    undefined
  );
  const [healSpot, setHealSpot] = React.useState<SpotName | undefined>(
    undefined
  );

  return (
    <>
      <Field
        settingsSpot={<Settings />}
        coin={<Coin />}
        activeSpot={
          <Card
            spotName={SpotName.ACTIVE_SPOT}
            onNewClick={handleCreatorOpen}
            onEditClick={handleEditorOpen}
            onDoDamageClick={setDamageSpot}
            onHealClick={setHealSpot}
          />
        }
        bench1={
          <Card
            spotName={SpotName.BENCH_1}
            onNewClick={handleCreatorOpen}
            onEditClick={handleEditorOpen}
            onDoDamageClick={setDamageSpot}
            onHealClick={setHealSpot}
          />
        }
        bench2={
          <Card
            spotName={SpotName.BENCH_2}
            onNewClick={handleCreatorOpen}
            onEditClick={handleEditorOpen}
            onDoDamageClick={setDamageSpot}
            onHealClick={setHealSpot}
          />
        }
        bench3={
          <Card
            spotName={SpotName.BENCH_3}
            onNewClick={handleCreatorOpen}
            onEditClick={handleEditorOpen}
            onDoDamageClick={setDamageSpot}
            onHealClick={setHealSpot}
          />
        }
        bench4={
          <Card
            spotName={SpotName.BENCH_4}
            onNewClick={handleCreatorOpen}
            onEditClick={handleEditorOpen}
            onDoDamageClick={setDamageSpot}
            onHealClick={setHealSpot}
          />
        }
        bench5={
          <Card
            spotName={SpotName.BENCH_5}
            onNewClick={handleCreatorOpen}
            onEditClick={handleEditorOpen}
            onDoDamageClick={setDamageSpot}
            onHealClick={setHealSpot}
          />
        }
      />
      <Modal
        isOpen={creatorModalIsOpen}
        onCloseEnd={() => setEditingSpot(undefined)}
        variant="custom"
      >
        <CardCreator
          editingSpot={editingSpot}
          onClose={() => setCreatorModalIsOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={editorModalIsOpen}
        onCloseEnd={() => setEditingSpot(undefined)}
        variant="custom"
      >
        <CardEditor
          editingSpot={editingSpot}
          onClose={() => setEditorModalIsOpen(false)}
        />
      </Modal>
      <Popup isOpen={!!damageSpot} onClose={() => setDamageSpot(undefined)}>
        <DoDamage
          variant="damage"
          spotName={damageSpot}
          onClose={() => setDamageSpot(undefined)}
        />
      </Popup>
      <Popup isOpen={!!healSpot} onClose={() => setHealSpot(undefined)}>
        <DoDamage
          variant="heal"
          spotName={healSpot}
          onClose={() => setHealSpot(undefined)}
        />
      </Popup>
    </>
  );
}
