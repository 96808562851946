export default function getDamageCounterThreshold(damageCounters: number) {
  if (damageCounters >= 10) {
    return "red";
  }

  if (damageCounters >= 5) {
    return "orange";
  }

  if (damageCounters >= 1) {
    return "yellow";
  }

  return "none";
}
