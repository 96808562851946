import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type BurnedChangePayload = {
  spotName: SpotName;
  burned: boolean;
};

function isBurnedChange(
  actionType: GameAction,
  payload: unknown
): payload is BurnedChangePayload {
  return actionType === GameAction.BURNED_CHANGE;
}

export default function handleBurnedChange(
  state: GameState,
  action: ReducerAction
) {
  if (isBurnedChange(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (!spot) {
      console.error(GameAction.BURNED_CHANGE, "Spot is undefined");
      return state;
    }

    return {
      ...state,
      spots: {
        ...state.spots,
        [spotName]: {
          ...spot,
          burned: action.payload.burned,
        },
      },
    };
  }

  return state;
}
