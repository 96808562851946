import Pokemon from "../interfaces/Pokemon";
import Type from "../enums/Type";

const EmptyCard: Pokemon = {
  damageCounters: 0,
  poisoned: false,
  burned: false,
  type: Type.COLORLESS,
};

export default EmptyCard;
