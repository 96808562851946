import Pokemon from "../interfaces/Pokemon";
import { Button } from "../elements";
import SpecialConditionPoisonedSource from "../images/special-condition-poisoned.png";
import SpecialConditionBurnedSource from "../images/special-condition-burned.png";
import TypeIcon from "./TypeIcon";
import TypeColor from "../constants/TypeColor";
import TypeBkgImgSrc from "../constants/TypeBkgImgSrc";
import TypeTextColor from "../constants/TypeTextColor";
import "./BenchCardThumbnail.css";

interface BenchCardThumbailProps extends Pokemon {
  onEditClick: () => void;
}

export default function CardThumbail({
  hp,
  damageCounters,
  poisoned,
  burned,
  type,
  onEditClick,
}: BenchCardThumbailProps) {
  const remainingHP = (hp || 0) - damageCounters * 10;
  const textColor = TypeTextColor[type];

  return (
    <Button variant="custom" onClick={onEditClick}>
      <div
        className="BenchCardThumbnail"
        style={{
          backgroundColor: TypeColor[type],
          backgroundImage: `url(${TypeBkgImgSrc[type]})`,
          color: textColor,
        }}
      >
        <div className="Content">
          <div className="Type">
            <TypeIcon type={type} />
          </div>

          {!!hp && (
            <div className="HP">
              <div className="Remaining">{remainingHP}</div>
              <div className="Max">&#8260;&#8239;{hp}</div>
            </div>
          )}
        </div>

        {(poisoned || burned) && (
          <div className="SpecialConditions">
            {poisoned && (
              <img alt="Poisoned" src={SpecialConditionPoisonedSource} />
            )}
            {burned && <img alt="Burned" src={SpecialConditionBurnedSource} />}
          </div>
        )}
      </div>
    </Button>
  );
}
