import React from "react";
import "./Button.css";

interface ButtonProps extends React.PropsWithChildren {
  size?: "normal" | "small" | "large";
  variant?: "normal" | "delete" | "gray" | "custom";
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}

function Button({
  children,
  onClick,
  className,
  disabled = false,
  variant = "normal",
  size = "normal",
}: ButtonProps) {
  return (
    <div
      className={`Element--Button ${className}`}
      data-size={size}
      data-variant={variant}
      data-disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {children}
    </div>
  );
}

export default Button;
