enum SpotName {
  ACTIVE_SPOT = "activeSpot",
  BENCH_1 = "bench1",
  BENCH_2 = "bench2",
  BENCH_3 = "bench3",
  BENCH_4 = "bench4",
  BENCH_5 = "bench5",
}

export default SpotName;
