enum Type {
  GRASS = "GRASS",
  FIRE = "FIRE",
  WATER = "WATER",
  LIGHTNING = "LIGHTNING",
  FIGHTING = "FIGHTING",
  PSYCHIC = "PSYCHIC",
  COLORLESS = "COLORLESS",
  DARKNESS = "DARKNESS",
  METAL = "METAL",
  DRAGON = "DRAGON",
  FAIRY = "FAIRY",
}

export default Type;
