import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import EmptyCard from "../../constants/EmptyCard";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type PlaceEmptyCardPayload = {
  spotName: SpotName;
};

function isPlaceEmptyCard(
  actionType: GameAction,
  payload: unknown
): payload is PlaceEmptyCardPayload {
  return actionType === GameAction.PLACE_EMPTY_CARD;
}

export default function handlePlaceEmptyCard(
  state: GameState,
  action: ReducerAction
) {
  if (isPlaceEmptyCard(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (spot) {
      console.error("There is already a card in this spot");
      return state;
    }

    return {
      ...state,
      spots: {
        ...state.spots,
        [spotName]: EmptyCard,
      },
    };
  }
  return state;
}
