import React from "react";

interface ModalContext {
  hasOpenModals: boolean;
  openModalIds: string[];
  openModal: (idToAdd: string) => void;
  closeModal: (idToRemove: string) => void;
}

export default React.createContext<ModalContext>({
  hasOpenModals: false,
  openModalIds: [],
  openModal: () => {},
  closeModal: () => {},
});
