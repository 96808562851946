import React from "react";
import { Button, Modal } from "../elements";
import { useGame } from "../hooks";
import EmptySpot from "./EmptySpot";
import SpotName from "../enums/SpotName";
import ActiveCardThumbnail from "./ActiveCardThumbnail";
import BenchCardThumbnail from "./BenchCardThumbnail";
import HPPicker from "./HPPicker";
import "./Card.css";

interface CardProps {
  spotName: SpotName;
  onNewClick: (SpotName: SpotName) => void;
  onEditClick: (spotName: SpotName) => void;
  onDoDamageClick: (spotName: SpotName) => void;
  onHealClick: (spotName: SpotName) => void;
}

export default function Card({
  spotName,
  onNewClick,
  onEditClick,
  onDoDamageClick,
  onHealClick,
}: CardProps) {
  const [hpPickerModalIsOpen, setHPPickerModalIsOpen] = React.useState(false);

  const game = useGame();
  const pokemon = game.spots[spotName];

  if (typeof pokemon === "undefined") {
    return (
      <div>
        <EmptySpot
          name={spotName}
          onClick={() => {
            game.handlePlaceEmptyCard(spotName);
            onNewClick(spotName);
          }}
        />
      </div>
    );
  }

  const CardThumbnail =
    spotName === SpotName.ACTIVE_SPOT
      ? ActiveCardThumbnail
      : BenchCardThumbnail;

  return (
    <div className="Card">
      <CardThumbnail
        spotName={spotName}
        hp={pokemon.hp}
        damageCounters={pokemon.damageCounters}
        poisoned={pokemon.poisoned}
        burned={pokemon.burned}
        type={pokemon.type}
        onEditClick={() => {
          onEditClick(spotName);
        }}
      />
      <div className="Buttons">
        <Button variant="custom" onClick={() => onDoDamageClick(spotName)}>
          <div className="DoDamageButton Button">Dmg</div>
        </Button>
        <Button variant="custom" onClick={() => onHealClick(spotName)}>
          <div className="HealButton Button">Heal</div>
        </Button>
        {spotName === SpotName.ACTIVE_SPOT && (
          <>
            <Button
              variant="custom"
              onClick={() => setHPPickerModalIsOpen(true)}
            >
              <div className="HP-Type Button">HP</div>
            </Button>
            <Button
              variant="custom"
              onClick={() => {
                if (window.confirm("Are you sure to discard this card?")) {
                  game.handleRemoveCard(spotName);
                }
              }}
            >
              <div className="DiscardButton Button">&times;Crd</div>
            </Button>
          </>
        )}
      </div>
      <Modal isOpen={hpPickerModalIsOpen}>
        <HPPicker
          value={pokemon.hp}
          onChange={(value) => {
            setHPPickerModalIsOpen(false);
            game.handleSetHP(spotName, value);
          }}
          disableStepButtons
        />
      </Modal>
    </div>
  );
}
