import Pokemon from "../interfaces/Pokemon";
import { getDamageCriticalityThreshold } from "../helpers";
import SpotName from "../enums/SpotName";
import { Button } from "../elements";
import { useGame } from "../hooks";
import DamageCounterControl from "./DamageCounterControl";
import SpecialConditionPoisonedSource from "../images/special-condition-poisoned.png";
import SpecialConditionBurnedSource from "../images/special-condition-burned.png";
import TypeIcon from "./TypeIcon";
import TypeColor from "../constants/TypeColor";
import TypeBkgImgSrc from "../constants/TypeBkgImgSrc";
import TypeTextColor from "../constants/TypeTextColor";
import "./ActiveCardThumbnail.css";

interface ActiveCardThumbailProps extends Pokemon {
  onEditClick: () => void;
  spotName: SpotName;
}

export default function CardThumbail({
  hp,
  damageCounters,
  poisoned,
  burned,
  type,
  onEditClick,
  spotName,
}: ActiveCardThumbailProps) {
  const game = useGame();
  const remainingHP = (hp || 0) - damageCounters * 10;
  const hpCriticality = getDamageCriticalityThreshold(
    damageCounters,
    remainingHP,
    hp || 0
  );

  const textColor = TypeTextColor[type];

  return (
    <div
      className="ActiveCardThumbnail"
      style={{
        backgroundColor: TypeColor[type],
        backgroundImage: `url(${TypeBkgImgSrc[type]})`,
      }}
    >
      <div className="Content">
        {!!hp && (
          <div className="RemainingHP">
            <div data-criticality={hpCriticality}>{remainingHP}</div>
          </div>
        )}
        <Button size="small" onClick={onEditClick}>
          <header>
            {(poisoned || burned) && (
              <div className="SpecialConditions">
                {poisoned && (
                  <img alt="Poisoned" src={SpecialConditionPoisonedSource} />
                )}
                {burned && (
                  <img alt="Burned" src={SpecialConditionBurnedSource} />
                )}
              </div>
            )}
            <div className="Space" />
            {!!hp && (
              <div className="HP" style={{ color: textColor }}>
                <small>HP</small>
                {hp}
              </div>
            )}
            <div className="Type">
              <TypeIcon type={type} />
            </div>
          </header>
        </Button>

        <div className="HPDamageIndicator">
          {!!hp && (
            <div className="HPBar">
              <div className="BarBackground">
                <div
                  className="Bar"
                  data-criticality={hpCriticality}
                  style={{
                    width: `${(remainingHP / hp) * 100}%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <DamageCounterControl
          damageCounters={damageCounters}
          onRemove={() => {
            game.handleRemoveDamageCounter(spotName, 1);
          }}
          onAdd={() => {
            game.handleAddDamageCounter(spotName, 1);
          }}
          textColor={textColor}
        />
      </div>
    </div>
  );
}
