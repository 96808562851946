import GameAction from "../../enums/GameAction";
import SpotName from "../../enums/SpotName";
import GameState from "../../interfaces/GameState";
import ReducerAction from "./ReducerAction";

type PlaceEmptyCardPayload = {
  spotName: SpotName;
};

function isRemoveCard(
  actionType: GameAction,
  payload: unknown
): payload is PlaceEmptyCardPayload {
  return actionType === GameAction.REMOVE_CARD;
}

export default function handleRemoveCard(
  state: GameState,
  action: ReducerAction
) {
  if (isRemoveCard(action.type, action.payload)) {
    const spotName = action.payload.spotName;
    const spot = state.spots[spotName];

    if (!spot) {
      console.error("There already is no card in this spot.");
    }

    let newSpotsState = {
      ...state.spots,
      [spotName]: undefined,
    };

    if (spotName === SpotName.ACTIVE_SPOT) {
      const benchedPokemon = Object.values(newSpotsState).filter(Boolean);
      if (benchedPokemon.length === 1) {
        newSpotsState = Object.fromEntries(
          Object.keys(newSpotsState).map((key) => [key, undefined])
        );
        newSpotsState[SpotName.ACTIVE_SPOT] = benchedPokemon[0];
      }
    }

    return {
      ...state,
      spots: newSpotsState,
    };
  }
  return state;
}
