import Type from "../enums/Type";

const TypeTextColor = {
  [Type.COLORLESS]: "black",
  [Type.GRASS]: "black",
  [Type.FIRE]: "black",
  [Type.WATER]: "black",
  [Type.LIGHTNING]: "black",
  [Type.FIGHTING]: "black",
  [Type.PSYCHIC]: "black",
  [Type.DARKNESS]: "white",
  [Type.METAL]: "black",
  [Type.DRAGON]: "black",
  [Type.FAIRY]: "black",
} as const;

export default TypeTextColor;
